import LanguageUtil from "@/commons/LanguageUtil";
import { defineComponent, reactive, onMounted } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import { Field, Form } from "vee-validate";
import ErrorMessage from "@/presentation/components/error-message/ErrorMessage.vue";
import UserInteractor from "@/domain/usecases/UserInteractor";
import DIContainer from "@/core/DIContainer";
export default defineComponent({
    name: "UserProfileEditSkillPopup",
    components: {
        Field: Field,
        Form: Form,
        ErrorMessage: ErrorMessage
    },
    props: [
        "skill"
    ],
    setup: function (props, _a) {
        var emit = _a.emit;
        var store = useStore();
        var router = useRouter();
        var state = reactive({
            aLang: LanguageUtil.getInstance().lang,
            skillData: props.skill,
            errorUpdateUserProfileSkillMessage: null,
            userProfileInfo: store.state.userProfileInfo,
            userProfileSkills: store.state.userProfileSkills,
            listSkill: [],
            thresholdLengthFor2Column: 10,
        });
        var userInteractor = DIContainer.instance.get(UserInteractor);
        function serializeForm() {
            var formData = {};
            if (state.skillData.category_name == "業態" || state.skillData.category_name == "デジタルスキル" || state.skillData.category_name == "ビジネススキル") {
                var skillData = state.listSkill;
                skillData.forEach(function (input) {
                    var element = document.getElementById(input.skill_id);
                    var value = element.value;
                    if (value == 1 || value == 2) {
                        formData[input.skill_name] = {
                            "skill_id": input.skill_id,
                            'selected': value,
                        };
                    }
                });
            }
            else {
                var formInput = document.querySelectorAll('form input[type="checkbox"]:checked');
                formInput.forEach(function (input) {
                    formData[input.name] = input.value;
                });
            }
            return formData;
        }
        var experienceSelect = [
            { name: 'なし', is_selected: 0 },
            { name: '実務経験あり', is_selected: 1 },
            { name: '知見あり', is_selected: 2 },
        ];
        function onSubmit() {
            var formData = serializeForm();
            var data = [];
            var updateData = {
                skills: []
            };
            state.userProfileSkills.forEach(function (category) {
                data = data.concat(category.children_skill);
            });
            data.forEach(function (skill) {
                if (skill.category_id === props.skill.category_id) {
                    if (formData[skill.skill_name]) {
                        if (formData[skill.skill_name].selected) {
                            skill.selected = formData[skill.skill_name].selected;
                        }
                        else {
                            skill.selected = 1;
                        }
                    }
                    else {
                        skill.selected = false;
                    }
                }
                updateData.skills.push({
                    category_id: skill.category_id,
                    skill_id: skill.skill_id,
                    selected: skill.selected,
                });
            });
            return userInteractor
                .updateUserProfileSkills(updateData)
                .then(function (result) {
                store.commit("setUserProfileSkillsData", state.userProfileSkills);
                emit('close');
            }, function (error) {
                state.errorUpdateUserProfileSkillMessage = error.message;
            });
        }
        var skillsOrder = ['IT業', 'サービス業', '小売業', '製造業', '建設業', '飲食/宿泊業', '卸売業', 'その他'];
        onMounted(function () {
            props.skill.children_skill.sort(function (x, y) {
                return skillsOrder.indexOf(x.skill_name) - skillsOrder.indexOf(y.skill_name);
            });
            state.listSkill = props.skill.children_skill;
        });
        return {
            state: state,
            onSubmit: onSubmit,
            experienceSelect: experienceSelect
        };
    }
});
