import { reactive, defineComponent, onMounted, computed, watch } from "vue";
import { useRouter, useRoute } from "vue-router";
import { useStore } from "vuex";
import UserProfileEditSkillsPopup from "@/presentation/components/modals/user-profile-edit-skills/UserProfileEditSkills.vue";
import CommonService from '@/services/CommonService';
import UserInteractor from "@/domain/usecases/UserInteractor";
import DIContainer from "@/core/DIContainer";
import SkillPreload from "@/presentation/components/preloader/skill-preload/SkillPreload.vue";
import Constant from '@/commons/Constant';
export default defineComponent({
    name: "UserProfileSkill",
    components: {
        UserProfileEditSkillsPopup: UserProfileEditSkillsPopup,
        SkillPreload: SkillPreload
    },
    props: ["breadcrumb", "alert"],
    setup: function (props) {
        var store = useStore();
        var router = useRouter();
        var route = useRoute();
        var state = reactive({
            preload: true,
            showEditPopup: false,
            userProfileSkills: [],
            skillData: {},
            isOwner: false,
            isDisabledTeams: false,
        });
        var isDisabledTeams = computed(function () { return store.state.isDisabledTeams; });
        watch(function () { return store.state.isDisabledTeams; }, function (newVal) {
            state.isDisabledTeams = newVal;
        }, {
            immediate: true
        });
        var userInteractor = DIContainer.instance.get(UserInteractor);
        function openEditModal(categoryId) {
            state.skillData = (state.userProfileSkills.find(function (x) { return x.category_id === categoryId; }) || {});
            state.showEditPopup = true;
        }
        onMounted(function () {
            store.commit("checkReadGuideScreen", Constant.GUIDE_USER_SKILL);
            props.breadcrumb[2] = { name: "スキル", route: "" };
            var user = CommonService.getCookie("user");
            var user_infor = {};
            if (user) {
                user_infor = JSON.parse(decodeURIComponent(user));
                state.isOwner = user_infor.account_id == Number(route.params.userId);
            }
            userInteractor
                .getUserProfileSkills(Number(route.params.userId))
                .then(function (result) {
                state.preload = false;
                var skillList = [];
                result.data.categories.forEach(function (category) {
                    category.children_skill = [];
                    skillList.push(category);
                });
                result.data.skills.forEach(function (skill) {
                    var skillCategory = skillList.find(function (x) { return x.category_id === skill.category_id; });
                    if (skillCategory) {
                        skillCategory.children_skill.push(skill);
                    }
                });
                store.commit('setUserProfileSkillsData', skillList);
                state.userProfileSkills = skillList;
            })
                .catch(function (error) {
                state.preload = false;
            });
            store.dispatch("checkActiveTeamInput");
        });
        var skill_list = computed(function () {
            return state.userProfileSkills.filter(function (row) { return !(row.category_name == "業態" || row.category_name == "デジタルスキル" || row.category_name == "ビジネススキル"); });
        });
        var select_skill_list = computed(function () {
            return state.userProfileSkills.filter(function (row) { return row.category_name == "業態" || row.category_name == "デジタルスキル" || row.category_name == "ビジネススキル"; });
        });
        return {
            state: state,
            openEditModal: openEditModal,
            skill_list: skill_list,
            select_skill_list: select_skill_list,
            isDisabledTeams: isDisabledTeams
        };
    }
});
