<template id="modal-template">
    <transition name="modal">
        <div class="modal-mask">
            <div class="modal-wrapper">
                <div class="modal-container">
                    <div class="modal-body">
                        <slot name="body">
                            <div class="profile_skill__edit__container">
                                <div class="profile_skill__edit__container-title">
                                    編集中
                                </div>
                                <Form
                                    class="horizontal"
                                    @submit="onSubmit"
                                    v-slot="{ errors, isSubmitting }"
                                    :validation-schema="schema"
                                >
                                    <!--{{業態　デジタルスキル　ビジネススキル}}-->
                                    <div class="form-ctrl form-area"  v-if="state.skillData.category_name == '業態' || state.skillData.category_name == 'デジタルスキル' || state.skillData.category_name == 'ビジネススキル'">
                                        <label  class="text">
                                            {{ state.skillData.category_name }}
                                        </label>
                                        <div :class="{
                                          'form-select': state.listSkill.length <= state.thresholdLengthFor2Column,
                                          'form-checkbox-group': state.thresholdLengthFor2Column < state.listSkill.length
                                        }">
                                            <template
                                                v-for="skill in state.listSkill"
                                                v-bind:key="skill.skill_id"
                                            >
                                                <div class="form-select-group">
                                                    <label
                                                        :for="skill.skill_name"
                                                    >
                                                        {{ skill.skill_name }}:
                                                    </label>   
                                                    <select
                                                        :class="{ invalid: !skill.skill_id }"
                                                        name = "select_busines"
                                                        :id='`${skill.skill_id}`'
                                                        >
                                                        <option
                                                            v-for="(row, i) in experienceSelect"
                                                            :key="i"
                                                            :value="row.is_selected"
                                                            :selected= "row.is_selected == skill.selected"
                                                        >
                                                            {{ row.name }}
                                                        </option>
                                                    </select>
                                                </div>
                                            </template>
                                        </div>
                                    </div>
                                    <!--{{各種スキル}}-->
                                    <div class="form-ctrl" v-else>
                                        <label  class="text">
                                            {{ state.skillData.category_name }}
                                        </label>
                                        <div :class="{
                                          'form-group': state.listSkill.length <= state.thresholdLengthFor2Column,
                                          'form-checkbox-group': state.thresholdLengthFor2Column < state.listSkill.length
                                        }">
                                            <template
                                                v-for="skill in state.listSkill"
                                                v-bind:key="skill.skill_id"
                                            >
                                                <input
                                                    :id="skill.skill_name"
                                                    type="checkbox"
                                                    :name="skill.skill_name"
                                                    :value="skill.skill_id"
                                                    :checked="skill.selected"
                                                />
                                                <label
                                                    :for="skill.skill_name"
                                                >
                                                    {{ skill.skill_name }}
                                                </label>
                                            </template>
                                        </div>
                                    </div>

                                    <ErrorMessage
                                        v-if="
                                            state.errorUpdateUserProfileInfoMessage
                                        "
                                        :message="
                                            state.errorUpdateUserProfileInfoMessage
                                        "
                                    />

                                    <div
                                        class="profile_skill__edit__container-button"
                                    >
                                        <button
                                            @click="$emit('close')"
                                            type="button"
                                            class="btn-light-gray btn-md sweep-to-top"
                                        >
                                            キャンセル
                                        </button>
                                        <button
                                            :disabled="isSubmitting"
                                            :class="{
                                                submitting: isSubmitting
                                            }"
                                            type="submit"
                                            class="btn-blue btn-md sweep-to-top"
                                        >
                                            <i
                                                v-if="isSubmitting"
                                                class="fas fa-circle-notch fa-spin"
                                            ></i>
                                            編集完了
                                        </button>
                                    </div>
                                </Form>
                            </div>
                        </slot>
                    </div>
                </div>
            </div>
        </div>
    </transition>
</template>
<script lang="ts" src="@/presentation/components/modals/user-profile-edit-skills/UserProfileEditSkills.ts" />
<link type="scss" scoped src="@/presentation/components/modals/user-profile-edit-skills/UserProfileEditSkills.scss" />