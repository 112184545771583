<template>
  <div class="skill">
    <div class="skill__row">
      <label>
        <div class="preloader"></div>
      </label>
      <div class="skill__row__grid">
        <span class="skill__row__grid__item">
          <div class="preloader"></div>
        </span>
        <span class="skill__row__grid__item">
          <div class="preloader"></div>
        </span>
        <span class="skill__row__grid__item">
          <div class="preloader"></div>
        </span>
        <span class="skill__row__grid__item">
          <div class="preloader"></div>
        </span>
      </div>
    </div>
    <div class="skill__row">
      <label>
        <div class="preloader"></div>
      </label>
      <div class="skill__row__grid">
        <span class="skill__row__grid__item">
          <div class="preloader"></div>
        </span>
        <span class="skill__row__grid__item">
          <div class="preloader"></div>
        </span>
        <span class="skill__row__grid__item">
          <div class="preloader"></div>
        </span>
        <span class="skill__row__grid__item">
          <div class="preloader"></div>
        </span>
      </div>
    </div>
    <div class="skill__row">
      <label>
        <div class="preloader"></div>
      </label>
      <div class="skill__row__grid">
        <span class="skill__row__grid__item">
          <div class="preloader"></div>
        </span>
        <span class="skill__row__grid__item">
          <div class="preloader"></div>
        </span>
        <span class="skill__row__grid__item">
          <div class="preloader"></div>
        </span>
        <span class="skill__row__grid__item">
          <div class="preloader"></div>
        </span>
      </div>
    </div>
    <div class="skill__row">
      <label>
        <div class="preloader"></div>
      </label>
      <div class="skill__row__grid">
        <span class="skill__row__grid__item">
          <div class="preloader"></div>
        </span>
        <span class="skill__row__grid__item">
          <div class="preloader"></div>
        </span>
        <span class="skill__row__grid__item">
          <div class="preloader"></div>
        </span>
        <span class="skill__row__grid__item">
          <div class="preloader"></div>
        </span>
      </div>
    </div>
    <div class="skill__row">
      <label>
        <div class="preloader"></div>
      </label>
      <div class="skill__row__grid">
        <span class="skill__row__grid__item">
          <div class="preloader"></div>
        </span>
        <span class="skill__row__grid__item">
          <div class="preloader"></div>
        </span>
        <span class="skill__row__grid__item">
          <div class="preloader"></div>
        </span>
        <span class="skill__row__grid__item">
          <div class="preloader"></div>
        </span>
      </div>
    </div>
    <div class="skill__row">
      <label>
        <div class="preloader"></div>
      </label>
      <div class="skill__row__grid">
        <span class="skill__row__grid__item">
          <div class="preloader"></div>
        </span>
        <span class="skill__row__grid__item">
          <div class="preloader"></div>
        </span>
        <span class="skill__row__grid__item">
          <div class="preloader"></div>
        </span>
        <span class="skill__row__grid__item">
          <div class="preloader"></div>
        </span>
      </div>
    </div>
  </div>
</template>

<script lang="ts" src="@/presentation/components/preloader/skill-preload/SkillPreload.ts">
</script>

<style lang="scss" src="@/presentation/components/preloader/skill-preload/SkillPreload.scss" scoped>
</style>
