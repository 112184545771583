<template>
    <div class="skill">
        <SkillPreload v-if=" state.preload"/>
        <!-- {{ 業態・デジタルスキル・ビジネススキル }} -->
        <div class="skill__row"
            v-for="category in select_skill_list"
            v-bind:key="category.category_id"
        >
            <label>{{category.category_name}}</label>
            <div class="skill__row__example">
                <label>
                    実務経験あり
                </label>
                <div class="skill__row__grid">
                    <template
                        v-if="category.children_skill.filter(x => x.selected == 1).length > 0"
                        v-for="skill in category.children_skill"
                        v-bind:key="skill.skill_id"
                >
                        <span
                            class="skill__row__grid__item"
                            v-if="skill.selected && skill.selected == 1"
                        >
                            {{ skill.skill_name }}
                        </span>
                    </template>
                    <span
                        class="no-data"
                        v-if="category.children_skill.filter(x => x.selected == 1).length == 0"
                    >
                        情報がありません
                    </span>
                </div>
                <label>
                    知見あり
                </label>
                <div class="skill__row__grid">
                    <template
                        v-if="category.children_skill.filter(x => x.selected == 2).length > 0"
                        v-for="skill in category.children_skill"
                        v-bind:key="skill.skill_id"
                    >
                        <span
                            class="skill__row__grid__item"
                            v-if="skill.selected && skill.selected == 2"
                        >
                            {{ skill.skill_name }}
                        </span>
                    </template>
                    <span
                        class="no-data"
                        v-if="category.children_skill.filter(x => x.selected == 2).length == 0"
                    >
                        情報がありません
                    </span>
                </div>    
            </div>
            <a
                href="javascript:void(0);"
                class="icon-edit"
                @click="openEditModal(category.category_id)"
                v-if="state.isOwner"
                >
                <i
                    class="fal fa-edit"
                    aria-hidden="true"
                ></i>
            </a>
        </div>
    </div>

    <UserProfileEditSkillsPopup
        v-if="state.showEditPopup"
        :skill="state.skillData"
        @close="state.showEditPopup = false;"
    />
</template>
<script lang="ts" src="@/presentation/views/user/profile/skill/Skill.ts" />
<link type="scss" scoped src="@/presentation/views/user/profile/skill/Skill.scss" />